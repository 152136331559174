import React from 'react';
import AsyncSelect from 'react-select/async';
import PubSub from 'pubsub-js';

class FancySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: this.props.selectedOptions || [],
      availableOptions: this.props.options || [],
    };

    // Bind the methods
    this.filterContacts = this.filterContacts.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
  }

  // Subscribe to PubSub topic on mount
  componentDidMount() {
    if (this.props.topic) {
      this.TOKEN = PubSub.subscribe(this.props.topic, (msg, data) => {
        if (data.option) {
          // Add the new option to the availableOptions array
          this.setState((prevState) => ({
            availableOptions: [...prevState.availableOptions, data.option]
          }));
        }
      });
    }
  }

  // Unsubscribe from PubSub on unmount
  componentWillUnmount() {
    if (this.TOKEN) {
      PubSub.unsubscribe(this.TOKEN);
    }
  }

  // Filter options based on input value
  filterContacts(inputValue) {
    return this.state.availableOptions.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  // Promise to load options
  promiseOptions(inputValue) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterContacts(inputValue));
      }, 200);
    });
  }

  // Update selected options
  updateField(value) {
    this.setState({ selectedOptions: value });
  }

  // Return the field name
  fieldName() {
    return this.props.isMulti ? `${this.props.fieldName}[]` : this.props.fieldName;
  }

  render() {
    return (
      <div>
        <AsyncSelect
          className={this.props.cssClasses}
          name={this.fieldName()}
          isMulti={this.props.isMulti}
          defaultOptions={this.state.availableOptions} // Use availableOptions as default
          loadOptions={this.promiseOptions} // Use the promise function to load options
          value={this.state.selectedOptions} // Use selected options from state
          onChange={(value) => this.updateField(value)} // Handle select changes
        />
      </div>
    );
  }
}

export default FancySelect;
