import React from 'react';
import NumberField from './NumberField';
import TimelineOperatorField from './TimelineOperatorField';
import Select from 'react-select'

class TimelineSegment extends React.Component {

  selectOptions = {};

  constructor(props) {
    super(props);

    this.prepareSelectOptions();

    this.state = {
      date: props.date,
      time: props.time,
      day: props.day,
      duration: props.duration,
      timelineTaskId: props.timelineTaskId || "",
      locationId: props.locationId || "",
      equipmentItemIds: props.equipmentItemIds || [],
      uniformItemIds: props.uniformItemIds || [],
      operators: props.operators || [],
      notes: props.notes || "",
      taskSeverityLevel: props.taskSeverityLevel
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        id: this.props.id,
        date: this.props.date,
        day: this.props.day,
        time: this.props.time,
        duration: this.props.duration,
        timelineTaskId: this.props.timelineTaskId || "",
        locationId: this.props.locationId || "",
        equipmentItemIds: this.props.equipmentItemIds || [],
        uniformItemIds: this.props.uniformItemIds || [],
        operators: this.props.operators || [],
        notes: this.props.notes || "",
        taskSeverityLevel: this.props.taskSeverityLevel
      })
    }
  }

  prepareSelectOptions() {
    this.selectOptions = { uniform: [], equipment: [], tasks: [] };

    for (let i = 0; i < this.props.availableUniform.length; i++) {
      let uniform = this.props.availableUniform[i];
      this.selectOptions.uniform.push({ value: uniform.id, label: uniform.name });
    }

    for (let i = 0; i < this.props.availableEquipment.length; i++) {
      let equipment = this.props.availableEquipment[i];
      this.selectOptions.equipment.push({ value: equipment.id, label: equipment.name });
    }

    for (let i = 0; i < this.props.availableTasks.length; i++) {
      let task = this.props.availableTasks[i];
      this.selectOptions.tasks.push({ value: task.id, label: task.name });
    }
  }

  triggerUpdate(value, field) {
    this.props.updateCallback(this.props.index, field, value);
  }

  triggerRemove() {
    this.props.removeCallback(this.props.index);
  }

  triggerDuplicateOperators() {
    this.props.duplicateOperatorsCallback(this.props.index);
  }

  addOperator() {
    let newOperator = { quantity: 1, operator_id: (this.props.availableOperators[0].id || "") };

    let operators = this.props.operators;

    operators.push(newOperator);

    this.setState({ operators: operators }, this.triggerUpdate('operators', operators));
  }

  updateOperator(value, field, index) {
    let operators = this.state.operators;
    let operator = operators[index];

    operator[field] = value;
    operators[index] = operator;

    this.setState({ operators: operators }, this.triggerUpdate('operators', operators));
  }

  removeOperator(index) {
    let operators = this.state.operators;
    let operator = operators[index];

    if (operator.id) {
      this.updateOperator("1", "_destroy", index);
    } else {
      operators.splice(index, 1);
      this.setState({ operators: operators }, this.triggerUpdate('operators', operators));
    }
  }

  renderOperators() {
    let operators = this.state.operators || [];
    let name = this.props.fieldPrefix + "[operator_timeline_segments_attributes][]";
    let duplicateButton;
    if (this.props.index === 0){
      duplicateButton = <div className="cell-sm-auto align-right">
        <span className="-color -red" style={{ cursor: 'pointer' }} onClick={this.triggerDuplicateOperators.bind(this)}>Duplicate to other tasks</span>
      </div>
    }

    return (
      <div className="field compact">
        { duplicateButton }
        <div className="row">
          <div className="cell-sm"></div>
          <div className="cell-sm-auto -padding">
            <div className="btn -margin-top_3 -blue" style={{ padding: '6px 10px', fontSize: '2em', fontWeight: 'bold', lineHeight: '.8em' }} onClick={this.addOperator.bind(this)}>+</div>
          </div>
          <div className="cell-sm"></div>
        </div>
        <div>

          {operators.map(function (operator, index) {
            if (operator._destroy) {
              return (
                <span key={index}>
                  <input type="hidden" name={name + "[id]"} value={operator.id} />
                  <input type="hidden" name={name + "[_destroy]"} value="1" />
                </span>
              )

            } else {
              return (
                <div key={index} className="row">
                  <input type="hidden" name={name + "[id]"} value={operator.id || ""} />
                  <div className="cell-sm-9 -padding-right_4">
                    <select className="form-control -padding_3 -margin-top_3" name={name + "[operator_id]"} value={operator.operator_id} onChange={(e) => this.updateOperator(e.target.value, 'operator_id', index)}>
                      {this.props.availableOperators.map(function (o, i) {
                        return (
                          <option key={i} value={o.id}>{o.name}</option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="cell-sm-2">
                    <input className="form-control -padding_3 -margin-top_3 -text-center" style={{ backgroundColor: 'white' }} type="number" min="0" name={name + "[quantity]"} value={operator.quantity} onChange={(e) => this.updateOperator(e.target.value, 'quantity', index)} />
                  </div>

                  <div className="cell-sm align-center align-middle -margin-top_3">
                    <div className="btn -red" style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1em', padding: '0px 9px 3px' }} onClick={() => this.removeOperator(index)}>-</div>
                  </div>
                </div>
              )
            }
          }.bind(this))}
        </div>
        <label>Operators Required</label>
      </div>
    );
  }




  renderForm() {
    return (
      <div className="row -padding" ref={this.props.segmentRef}>
        <input type="hidden" name={this.props.fieldPrefix + "[id]"} value={this.props.id || ""} />
        <div className="cell-sm-7">
          <div className="field compact">
            <div className="row">
              <div className="cell-sm-8">
                <label>Task</label>
                <Select className="-padding-right_3 -margin-top_3" name={this.props.fieldPrefix + "[timeline_task_id]"} isMulti={false} options={this.selectOptions.tasks} value={this.state.timelineTaskId || ""} onChange={(value) => this.triggerUpdate(value, 'timelineTaskId')} />
              </div>

              <div className="cell-sm-4">
                <label>Task Risk Level</label>
                <select className="form-control -padding_3 -margin-top_3" name={this.props.fieldPrefix + "[task_severity_level]"} value={this.state.taskSeverityLevel || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'taskSeverityLevel')}>
                  {this.props.availableSeverityLevels.map(function (level, i) {
                    return <option key={i} value={level.value}>{level.label}</option>
                  }.bind(this))}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="cell-sm-auto">
              <div className="field compact">
                <select className="form-control -padding_3 -margin-top_3" name={this.props.fieldPrefix + "[day]"} value={this.state.day || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'day')}>
                  {this.props.dayArray.map(function (day, i) {
                    return <option key={i} value={day}>{day}</option>
                  }.bind(this))}
                </select>
                <label>Day</label>
              </div>
            </div>
            <div className="cell-sm">
              <div className="field compact">
                <select className="form-control -padding_3 -margin-top_3" name={this.props.fieldPrefix + "[date]"} value={this.state.date || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'date')}>
                  {this.props.dateArray.map(function (date, i) {
                    return <option key={i} value={date}>{date}</option>
                  }.bind(this))}
                </select>
                <label>Date</label>
              </div>
            </div>
            <div className="cell-sm">
              <div className="field compact">
                <select className="form-control -padding_3 -margin-top_3" name={this.props.fieldPrefix + "[time]"} value={this.state.time || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'time')}>
                  {this.props.timeArray.map(function (time, i) {
                    return <option key={i} value={time}>{time}</option>
                  }.bind(this))}
                </select>
                <label>Time</label>
              </div>
            </div>
            <div className="cell-sm-auto">
              <div className="field compact">
                <input type="number" step="0.5" min="0" name={this.props.fieldPrefix + "[duration]"} value={this.state.duration || 0} onChange={(e) => this.triggerUpdate(e.target.value, 'duration')} className="form-control -padding_3 -margin-top_3 -text-center" style={{ backgroundColor: 'white' }} />
                <label>Duration (hours)</label>
              </div>
            </div>
          </div>

          <div className="field compact">
            <select className="form-control -padding_3 -margin-top_3" name={this.props.fieldPrefix + "[building_floor_ids][]"} value={this.props.locationId || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'locationId')}>
              {this.props.availableLocations.map(function(location, i){
                return <option key={i} value={location.id}>{location.name}</option>
              }.bind(this))}
            </select>
            <label>Location</label>
          </div>

          <div className="field compact">
            <Select className="-margin-top_3" name={this.props.fieldPrefix + "[equipment_ids][]"} isMulti={true} options={this.selectOptions.uniform} value={this.props.uniformItemIds || []} onChange={(value) => this.triggerUpdate(value, 'uniformItemIds')} />
            <label>Required Uniform & PPE</label>
          </div>

          <div className="field compact">
            <Select className="-margin-top_3" name={this.props.fieldPrefix + "[equipment_ids][]"} isMulti={true} options={this.selectOptions.equipment} value={this.props.equipmentItemIds || []} onChange={(value) => this.triggerUpdate(value, 'equipmentItemIds')} />
            <label>Required Equipment</label>
          </div>

          <div className="field compact">
            
                      <textarea className="form-control -margin-top_3 -padding_4" style={{ backgroundColor: 'white' }} name={this.props.fieldPrefix + "[notes]"} defaultValue={this.state.notes || ""} onChange={(e) => this.triggerUpdate(e.target.value, 'notes')} rows="5" />
            <label>Notes</label>
          </div>
        </div>
        <div className="cell-sm-5 -padding-left_3">
          {this.renderOperators()}
          {/*<TimelineOperatorField availableOperators={this.props.availableOperators || []} value={this.props.operators || []} field="operators" name={this.props.fieldPrefix + "[operator_timeline_segments_attributes][]"} updateCallback={this.triggerUpdate.bind(this)} />*/}
        </div>

      </div>
    );

  }

  render() {

    return (
      <div className="vertical-timeline-element--work vertical-timeline-element">
        <div className="vertical-timeline-element-icon pending"><div className="number">{this.props.index + 1}</div></div>
        <div className="vertical-timeline-element-content">
          <div className="vertical-timeline-element-content-arrow"></div>
          <div className="vertical-timeline-element-time">{this.props.time}</div>
          <div className="form">
            {this.renderForm()}
          </div>

          <div className="row">
            <div className="cell-sm"></div>

            <div className="cell-sm-auto">
              <span className="-color -red" style={{ cursor: 'pointer' }} onClick={this.triggerRemove.bind(this)}>Remove Task</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default TimelineSegment;