// working
// SGGs
//
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
// @ts-ignore
import Interaction from '../../../plugins/interaction.ts';
import { addClass, removeClass, toggleClass } from '../../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import PubSub from 'pubsub-js';
import Dexie from 'dexie';

export default class extends Controller {
  static targets = ['handler', 'actions'];

  declare handlerTarget: any;
  declare handlerTargets: any[];

   declare lists: Array<any>;
   declare cache: any;

  async connect() {
    try {
      this.cache = await new Dexie('sggs').open();
      this.checkCollections(true);
    } catch(e) {
      console.log(e)
    }

    this.cache.tables.forEach(function (table) {
      console.log({TableName: table.name})
    });
    console.log (this.cache.table('actions').count());

  }

  initialize() { this.lists = []; }

  async select(event: any, publish: boolean = true) {
    const target: HTMLInputElement = (event.type === 'change' ? event.srcElement : event),
          uuid: string = target.value,
          checked: boolean = target.checked;

    if (checked) {
      addClass(document.getElementById(`sgg#${ uuid }`), 'selected');
      addClass(target.parentNode, 'selected');
    } else {
      removeClass(document.getElementById(`sgg#${ uuid }`), 'selected');
      removeClass(target.parentNode, 'selected');
    }

    if (publish) {
      PubSub.publish('sggs.actions', {
        uuid: uuid,
        selected: checked
      });
    }
  }

  async close(event?: any) {
    if (event) { event.preventDefault(); }
    // our cache is causing issues on live so commenting it for now.
    // const actionsTable: any = this.cache.table('actions'); //commented

    // Do the cleanup here instead of using the disconnect() method since pagination, and moving on to the next page,
    // will call disconnect.
    //
    // this.checkCollections(false); //commented

    // await actionsTable.clear(); //commented

    this.lists = [];

    new Interaction().close();
  }

  private checkCollections(value: boolean) {
    const actionsTable: any = this.cache.table('actions');

    actionsTable.each((action: any /* TODO: Create an Action Interface */) => {
      const handler: HTMLInputElement = document.querySelector(`input[type="checkbox"][value="${ action.uuid }"`);

      // Check if the handler exists, if it doesn't, it means the user has moved onto another page
      //
      if (handler) {
        handler.checked = value;
        this.select(handler, false);
      }
    });
  }

  disconnect() { }
}